import {Box, Tooltip, Typography} from "@mui/material";
import Header from "../../components/Header";
import React from "react";
import JobCard from "../../PDF/JobCard";
import { PDFViewer } from '@react-pdf/renderer';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import {BarChart, ScatterChart} from "@mui/x-charts";
import {
    useGetProductionBreakdownQuery,
    useGetProductionByMassQuery, useGetProductionMonthsQuery,
    useGetProductionQuery
} from "../../redux/features/dashboard";
import Select, {components, ContainerProps} from "react-select";

const Dashboard = () => {
    const {data: productionMonths} = useGetProductionMonthsQuery({})
    const [productionMonth, setProductionMonth] = React.useState("");
    const {data: production} = useGetProductionQuery(productionMonth)
    const {data: productionByMass} = useGetProductionByMassQuery(productionMonth)
    const {data: productionBreakdown} = useGetProductionBreakdownQuery(productionMonth)

    console.log("PRODUCTION", production)
    const SelectContainer = ({
                                 children,
                                 ...props
                             }: ContainerProps) => {
        return (
            <Tooltip title={""} content={'customise your select container'}>
                <components.SelectContainer {...props}>
                    {children}
                </components.SelectContainer>
            </Tooltip>
        );
    };

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: 'lightgray',
            padding: '10px 10px 10px 10px',
            border: '1px solid black',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            spacing: 10,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'hotpink' : 'white',
        }),
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                <Header title={"DASHBOARD"} subtitle={"Welcome to your dashboard"}></Header>
            </Box>
            <Select
                styles={customStyles}
                closeMenuOnSelect={true}
                components={{SelectContainer}}
                options={productionMonths && productionMonths.map((productionMonth: any) => ({
                    value: productionMonth,
                    label: productionMonth
                }))}
                onChange={(branch: any) => {
                    setProductionMonth(branch.value)
                }}
                placeholder={"Select Production Month"}
            />
            <Typography variant="h3">Production Chart By Units for {productionMonth === "" ? "Lifetime" : productionMonth}</Typography>
            <BarChart
                xAxis={[{ scaleType: 'band', data: production ? production.xAxis : [] }]}
                series={[{data: production ? production.yAxis : []}]}
                width={1000}
                height={300}
                yAxis = {[
            {
                valueFormatter: (value) => `${(value / 1000).toLocaleString()}k`,
                label: 'Units',
            },
            ]}
            />
            <Typography variant="h3">Production Chart By Kg for {productionMonth === "" ? "Lifetime" : productionMonth}</Typography>
            <BarChart
                xAxis={[{ scaleType: 'band', data: productionByMass ? productionByMass.xAxis : [] }]}
                series={[{data: productionByMass ? productionByMass.yAxis : []}]}
                width={1000}
                height={300}
                yAxis = {[
                    {
                        valueFormatter: (value) => `${(value / 1000).toLocaleString()}T`,
                        label: 'mass (kg)',

                                    },
                ]}
            />
            <Typography variant="h3">Production Chart Breakdown for {productionMonth === "" ? "Lifetime" : productionMonth}</Typography>
            <BarChart
                xAxis={[{ scaleType: 'band', data: productionBreakdown ? productionBreakdown.xAxis : [] }]}
                series={[{data: productionBreakdown ? productionBreakdown.yAxis : []}]}
                width={1000}
                height={300}
                yAxis = {[
                    {
                        label: 'minutes (min)',
                    },
                ]}
            />
            {/*<Box sx={{height: 100}}>*/}
            {/*    <PDFViewer style={{ width: '100%', height: '500px' }}>*/}
            {/*        <JobCard />*/}
            {/*    </PDFViewer>*/}
            {/*</Box>*/}


        </Box>

    );
}

export default Dashboard;