import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {store} from "../store";
import {baseQueryWithReauth, productBaseQuery} from "./apiSlice";

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: baseQueryWithReauth(productBaseQuery),

    tagTypes: ['dashboard'],
    endpoints: build => ({
        GetProduction: build.query({
            providesTags: ['dashboard'],
            query:(productionMonth: string) => ({
                url: `/dashboard/production?byMonth=${productionMonth}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.dashboard}
        }),
        GetProductionByMass: build.query({
            providesTags: ['dashboard'],
            query:(productionMonth: string) => ({
                url: `/dashboard/productionByMass?byMonth=${productionMonth}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.dashboard}
        }),
        GetProductionBreakdown: build.query({
            providesTags: ['dashboard'],
            query:(productionMonth: string) => ({
                url: `/dashboard/productionBreakdown?byMonth=${productionMonth}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.dashboard}
        }),
        GetProductionMonths: build.query({
            providesTags: ['dashboard'],
            query:() => ({
                url: `/dashboard/productionMonths`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>  {
                return response.dashboard}
        }),
    })
})

export const  {
    useGetProductionQuery,
    useGetProductionByMassQuery,
    useGetProductionBreakdownQuery,
    useGetProductionMonthsQuery
} = dashboardApi

