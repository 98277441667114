import Header from "../../components/Header";
import {Alert, AlertProps, Box, Button, Grid, Modal, TextField, Tooltip, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {
    DataGrid, GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp
} from "@mui/x-data-grid";
import Snackbar from '@mui/material/Snackbar';
import React, {useEffect, useState} from "react";
import {useGetUsersQuery} from '../../redux/features/uses'

import type {FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import {useGetMachinesQuery} from "../../redux/features/machines";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {useGetMouldsByMachineIdQuery, useGetMouldsQuery} from "../../redux/features/moulds";
import {
    useGetMachinePerformanceQuery, useLazyGetMachinePerformanceDownloadQuery, usePostMachinePerformanceMutation
} from "../../redux/features/machinePerformance";
import {useSelector} from "react-redux";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import Select, {components, ContainerProps} from "react-select";
import {Formik, FormikHelpers, FormikValues, useFormikContext} from "formik";
import * as yup from "yup";
import {useGetCustomersQuery} from "../../redux/features/customers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import * as XLSX from 'xlsx';
interface IRow {
    row: {
        access: string
    }
}

interface IDataRow {
    field: string;
    headerName: string;
    flex?: number;
    cellClassName?: string;
    type?: string;
    headerAlign?: string;
    align?: string;
    renderCell?: ({row: {access}}: IRow) => JSX.Element;
}

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps): any {
    const {setRows, setRowModesModel} = props;

    const handleClick = (id: any) => {
        setRows((oldRows) => [...oldRows, {id, name: '', age: '', isNew: true}]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };
}

function getRowId(row: any) {
    console.log("ROW ROW RWO ", row)
    return row.id;
}

function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
}

const ShiftData = () => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    const {data: machinePerformance,} = useGetMachinePerformanceQuery({});
    const [loading, setLoading] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);
    const machineStatus = ['active', 'offline', 'maintenance'];

    const auth: any = useSelector((state: any) => state.auth)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editRow, setEditRow] = useState<any>([]);
    const [machineSelected, setMachineSelected] = React.useState<any>('');
    const [mouldSelected, setMouldSelected] = React.useState<any>('');

    const [customerId, setCustomerId] = React.useState<any>(null);
    const [submitted, setSubmitted] = React.useState(false);
    const [addMachinePerformance, {data, error, isLoading, isSuccess}] = usePostMachinePerformanceMutation()
    const [shiftDate, setShiftDate] = React.useState<any>('');
    const {data: machines,} = useGetMachinesQuery({});
    const {data: customers,} = useGetCustomersQuery({});

    const {data: moulds,} = useGetMouldsByMachineIdQuery(machineSelected);


    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    console.log("Machine Performance:", machinePerformance)
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleCloseSnackbar = () => setSnackbar(null);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        setSnackbar({children: error.toString(), severity: 'error'});
    }, []);

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
        console.log("ID", id)
    };

    const handleCancelClick = (id: GridRowId) => () => {
        console.log("ID", id)
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
        console.log("Users", machinePerformance)
        const editedRow = machinePerformance.find((row: any) => row.ID === id);
        // if (editedRow!.isNew) {
        //     setRows(rows.filter((row: any) => row.ID !== id));
        // }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleDownload = async () => {
        try {
            setLoading(true);
            // const myHeaders = new Headers();
            // myHeaders.append("Authorization", 'Bearer ' + auth.accessToken);
            //
            // const requestOptions = {
            //     method: "GET",
            //     headers: myHeaders
            // };
            //
            // const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "/machine_performance/download", requestOptions);
            //
            // if (!response.ok) {
            //     throw new Error("HTTP error " + response.status);
            // }
            //
            // const blob = await response.blob();
            // const url = window.URL.createObjectURL(blob);
            // const link = document.createElement('a');
            //
            // link.href = url;
            // link.download = 'machine_performance.csv'; // rename this to your preferred filename
            // document.body.appendChild(link);
            //
            // link.click();
            // link.remove();

            let ws = XLSX.utils.json_to_sheet(machinePerformance);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "sheet");
            let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
            let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
            XLSX.writeFile(wb, `machine_performance.xlsx`);
            setLoading(false);
        } catch (error) {
            console.error('Fetch failed:', error);
        }

    }

    const onMachineChange = (storeSelected: any) => {
        console.log(storeSelected)
        // const store = machines.find((machine: any) => machines.Franchise + "-" + store.StoreNumber === storeSelected.value)
        setMachineSelected(storeSelected.value)
        setMouldSelected('')
        //Have to set mould back to '' here...
        console.log("NEW MOULD MACHINE SELECTED", storeSelected)
    }

    const initialValues = {
        shiftDate: "",
        machineToMouldLinkId: "",
        shiftPeriod: "",
        productionMCCycleSeconds: "",
        productsProduced: "",
        startUpReject: "",
        shortMouldingReject: "",
        wrongColourReject: "",
        damagedCrateReject: "",
        saleCost: "",
        materialsCost: "",
        limitSwitch: "",
        breakdown: "",
        vacuumPipeCleaner: "",
        oilLeak: "",
        pumpChamber: "",
        mouldChange: "",
        plannedMaintenance: "",
        loadShedding: "",
        loadCurtailment: "",
        greasingMould: "",
        materialChange: "",
        toolroomFitting: "",
        waterPipeBurst: "",
        waterChannelBlocked: "",
    };

    const checkoutSchema = yup.object().shape({
        productsProduced: yup.string().required(),
        shiftPeriod: yup.string().required().matches(/day|night|day 1\/3|afternoon 1\/3|night 1\/3/),
        productionMCCycleSeconds: yup.string().required(),
        startUpReject: yup.string().required(),
        shortMouldingReject: yup.string().required(),
        wrongColourReject: yup.string().required(),
        damagedCrateReject: yup.string().required(),
        saleCost: yup.string().required(),
        materialsCost: yup.string().required(),
        limitSwitch: yup.string().required(),
        breakdown: yup.string().required(),
        vacuumPipeCleaner: yup.string().required(),
        oilLeak: yup.string().required(),
        pumpChamber: yup.string().required(),
        mouldChange: yup.string().required(),
        plannedMaintenance: yup.string().required(),
        loadShedding: yup.string().required(),
        loadCurtailment: yup.string().required(),
        greasingMould: yup.string().required(),
        materialChange: yup.string().required(),
        toolroomFitting: yup.string().required(),
        waterPipeBurst: yup.string().required(),
        waterChannelBlocked: yup.string().required(),

    });
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: 'lightgray',
            padding: '10px 10px 10px 10px',
            border: '1px solid black',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            spacing: 10,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'hotpink' : 'white',
        }),
    };

    const shiftOptions = [
        {value: 'day', label: 'Day'},
        {value: 'night', label: 'Night'},
        {value: 'day 1/3', label: 'Day 1/3'},
        {value: 'afternoon 1/3', label: 'Afternoon 1/3'},
        {value: 'night 1/3', label: 'Night 1/3'},
    ]

    const setDate = (date: any) => {
        let day = new Date(date).getDate();
        console.log("SHIFT DATE DAY", day)
        let month = new Date(date).getMonth() + 1;
        console.log("SHIFT DATE MONTH", month)
        let res = new Date(date).getFullYear() + "-" + ((month < 10) ? "0" + month : month) + "-" + ((day < 10) ? "0" + day : day);
        console.log("SHIFT DATE res", res)
        setShiftDate(res)
    };

    const onMouldChange = (storeSelected: any) => {
        // const store = machines.find((machine: any) => machines.Franchise + "-" + store.StoreNumber === storeSelected.value)
        console.log("MOULD CHANGE", storeSelected)
        console.log("MOULD CHANGE ID ", storeSelected.value)
        setMouldSelected(storeSelected.value)
    }

    const handleFormSubmit = (values: FormikValues, formikHelpers: FormikHelpers<any>) => {
        setSubmitted(true);
        console.log("VALES: ", values);
        values.shiftDate = shiftDate;
        if (customerId) {
            values.customerId = customerId
        }
        addMachinePerformance(values);
        setMachineSelected('');
        formikHelpers.resetForm();
    };

    const SelectContainer = ({
                                 children,
                                 ...props
                             }: ContainerProps) => {
        return (
            <Tooltip title={""} content={'customise your select container'}>
                <components.SelectContainer {...props}>
                    {children}
                </components.SelectContainer>
            </Tooltip>
        );
    };

    const columns: GridColDef[] = [
        {field: "id", headerName: "ID"},
        {field: "shiftDate", headerName: "shiftDate", width: 180},
        {field: "shiftPeriod", headerName: "Shift Period"},
        {field: "machineName", headerName: "Machine Name"},
        {field: "serial", headerName: "Mould Serial"},
        {field: "product", headerName: "Mould Product"},
        {field: "weight", headerName: "Mould Weight"},
        {field: "companyName", headerName: "Customer Name"},
        {field: "productsProduced", headerName: "Product"},
        {field: "startUpReject", headerName: "Start Up Reject"},
        {field: "shortMouldingReject", headerName: "Short Moulding Reject"},
        {field: "wrongColourReject", headerName: "Wrong Colour Reject"},
        {field: "damagedCrateReject", headerName: "Damaged Create Reject"},
        {field: "limitSwitch", headerName: "Limit Switch"},
        {field: "breakdown", headerName: "Breakdown"},
        {field: "vacuumPipeCleaner", headerName: "Vacuum Pipe Cleaner"},
        {field: "oilLeak", headerName: "Oil Leak"},
        {field: "mouldDownTime", headerName: "Mould Down Time"},
        {field: "pumpChamber", headerName: "Pump Chamber"},
        {field: "mouldChange", headerName: "Mould Change"},
        {field: "plannedMaintenance", headerName: "Planned Maintenance"},
        {field: "loadShedding", headerName: "Load Shedding"},
        {field: "loadCurtailment", headerName: "Load Curtailment"},
        {field: "greasingMould", headerName: "Greasing Mould"},
        {field: "materialChange", headerName: "Material Change"},
        {field: "toolroomFitting", headerName: "Toolroom Fitting"},
        {field: "waterPipeBurst", headerName: "Water Pipe Burst"},
        {field: "waterChannelBlocked", headerName: "Water Channel Blocked"},
        {field: "manufactureCycleTime", headerName: "Manufacture MC Cycle Seconds"},
        {field: "productionMCCycleSeconds", headerName: "Production MC Cycle Seconds"},
        {field: "shiftMinutes", headerName: "Shift Minutes"},
        {field: "branchLocation", headerName: "Branch Location"},
        {field: "mouldType", headerName: "Mould Type"},
        {field: "firstName", headerName: "User"},
        {field: "totalSecondsAvailable", headerName: "Total Seconds Available"},
        {field: "totalDowntime", headerName: "Total Downtime"},
        {field: "expectedOutput", headerName: "Expected Output"},
        {field: "actualOutput", headerName: "Actual Output"},
        {field: "scrapUnits", headerName: "Scrap Units"},
        {field: "scrapPercentage", headerName: "Scrap Percentage"},
        {field: "quality", headerName: "Quality"},
        {field: "availabilityPercentage", headerName: "Availability Percentage"},
        {field: "availabilityPercentageMould", headerName: "Availability Percentage Mould"},
        {field: "performanceEfficiency", headerName: "Performance Efficiency"},
        {field: "oeeMachine", headerName: "OEE Machine"},
        {field: "oeeMould", headerName: "OEE Mould"},
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />
                ];
            },
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
            <Box m="20px">
                <Header
                    title="Machine Performance"
                    subtitle="List of Shift Data"
                />
                <Box display="flex" justifyContent="flex-start">
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        onClick={handleDownload} download
                        href={''}>
                        Download
                    </Button>
                    {/*<Button*/}
                    {/*    variant="contained"*/}
                    {/*    color="secondary"*/}
                    {/*    onClick={() => setIsModalOpen(true)}*/}
                    {/*    href={''}>*/}
                    {/*    Add Shift Data*/}
                    {/*</Button>*/}
                </Box>
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                    </Snackbar>
                )}
                <Modal
                    open={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false)
                        setEditRow([])
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4
                    }}>
                        <Formik
                            onSubmit={(values, formikHelpers) => handleFormSubmit(values, formikHelpers)}
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container flex={'auto'} spacing={2}>
                                        <Grid item xs={6}>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <Grid item xs={2}>
                                                        <Box
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >Date of Shift:</Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <DatePicker
                                                            label="Date of Shift"
                                                            onChange={setDate}
                                                            name="shiftDate"
                                                        />
                                                    </Grid>
                                                </div>

                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <Grid item xs={2}>
                                                        <Box
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >Shift Options:</Box>
                                                    </Grid>
                                                    <div style={{padding: "20px 0 0 20px", minWidth: 400}}>
                                                        <Select
                                                            styles={customStyles}
                                                            closeMenuOnSelect={true}
                                                            options={shiftOptions.map((shiftOption: any) => ({
                                                                value: shiftOption.id,
                                                                label: shiftOption.label
                                                            }))}
                                                            onChange={(shiftPeriod: any) => {
                                                                const event = {
                                                                    target: {
                                                                        name: 'shiftPeriod',
                                                                        value: shiftPeriod.value
                                                                    }
                                                                };
                                                                handleChange(event);
                                                            }}
                                                            placeholder={"Select Shift Period"}

                                                        />
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <Box
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >Machine:</Box>
                                                    <div style={{padding: "20px 0 0 20px", minWidth: 400}}>
                                                        <Select
                                                            styles={customStyles}
                                                            closeMenuOnSelect={true}
                                                            components={{SelectContainer}}
                                                            options={machines && machines.map((machine: any) => ({
                                                                value: machine.id,
                                                                label: machine.machineName
                                                            }))}
                                                            onChange={selectedOptions => {
                                                                const event = {target: {name: 'machineName', value: selectedOptions.value}};
                                                                handleChange(event);
                                                                const mouldEvent = {target: {name: 'machineToMouldLinkId', value: ''}};
                                                                handleChange(mouldEvent);

                                                                onMachineChange(selectedOptions)
                                                            }}
                                                            defaultValue={machineSelected}
                                                        />
                                                    </div>
                                                </div>

                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <Box
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >Mould:</Box>
                                                    <div style={{padding: "20px 0 0 20px", minWidth: 400}}>
                                                        <Select
                                                            styles={customStyles}
                                                            closeMenuOnSelect={true}
                                                            options={moulds && moulds.map((moulds: any) => ({
                                                                value: moulds.id,
                                                                label: moulds.mould_serial + " " + moulds.mould_product
                                                            }))}
                                                            onChange={onMouldChange}
                                                            defaultValue={mouldSelected}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <Box
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >Customer:</Box>
                                                    <div style={{padding: "20px 0 0 20px", minWidth: 400}}>
                                                        <Select
                                                            styles={customStyles}
                                                            closeMenuOnSelect={true}
                                                            components={{SelectContainer}}
                                                            options={customers && customers.map((customer: any) => ({
                                                                value: customer.id,
                                                                label: customer.companyName
                                                            }))}
                                                            onChange={selectedOptions => {
                                                                const event = {target: {name: 'customer', value: selectedOptions.value}};
                                                                handleChange(event);
                                                            }}
                                                            defaultValue={customerId}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                                    <Box
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >Production M/C Cycle Seconds:</Box>
                                                    <TextField
                                                        fullWidth
                                                        variant="filled"
                                                        type="text"
                                                        label="Production M/C Cycle Seconds
"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.productionMCCycleSeconds}
                                                        name="productionMCCycleSeconds"
                                                        error={!!touched.productionMCCycleSeconds && !!errors.productionMCCycleSeconds}
                                                        helperText={touched.productionMCCycleSeconds && errors.productionMCCycleSeconds}
                                                        sx={{gridColumn: "span 1"}}
                                                    />
                                                </div>
                                            </div>

                                            <Grid xs={8}></Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box
                                                alignItems="center"
                                                justifyContent="center"
                                            >Production:</Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Crates Produced"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.productsProduced}
                                                    name="productsProduced"
                                                    error={!!touched.productsProduced && !!errors.productsProduced}
                                                    helperText={touched.productsProduced && errors.productsProduced}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Start Up"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.startUpReject}
                                                    name="startUpReject"
                                                    error={!!touched.startUpReject && !!errors.startUpReject}
                                                    helperText={touched.startUpReject && errors.startUpReject}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Short Moulding"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.shortMouldingReject}
                                                    name="shortMouldingReject"
                                                    error={!!touched.shortMouldingReject && !!errors.shortMouldingReject}
                                                    helperText={touched.shortMouldingReject && errors.shortMouldingReject}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Wrong Colour"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.wrongColourReject}
                                                    name="wrongColourReject"
                                                    error={!!touched.wrongColourReject && !!errors.wrongColourReject}
                                                    helperText={touched.wrongColourReject && errors.wrongColourReject}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Damaged Crates"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.damagedCrateReject}
                                                    name="damagedCrateReject"
                                                    error={!!touched.damagedCrateReject && !!errors.damagedCrateReject}
                                                    helperText={touched.damagedCrateReject && errors.damagedCrateReject}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box
                                                alignItems="center"
                                                justifyContent="center"
                                            >Prices:</Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Sale Cost"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.saleCost}
                                                    name="saleCost"
                                                    error={!!touched.saleCost && !!errors.saleCost}
                                                    helperText={touched.saleCost && errors.saleCost}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Material Cost"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.materialsCost}
                                                    name="materialsCost"
                                                    error={!!touched.materialsCost && !!errors.materialsCost}
                                                    helperText={touched.materialsCost && errors.materialsCost}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box
                                                alignItems="center"
                                                justifyContent="center"
                                            >Lost Minutes Analysis:</Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Planned Maintenance"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.plannedMaintenance}
                                                    name="plannedMaintenance"
                                                    error={!!touched.plannedMaintenance && !!errors.plannedMaintenance}
                                                    helperText={touched.plannedMaintenance && errors.plannedMaintenance}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Limit Switch"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.limitSwitch}
                                                    name="limitSwitch"
                                                    error={!!touched.limitSwitch && !!errors.limitSwitch}
                                                    helperText={touched.limitSwitch && errors.limitSwitch}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Breakdown"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.breakdown}
                                                    name="breakdown"
                                                    error={!!touched.breakdown && !!errors.breakdown}
                                                    helperText={touched.breakdown && errors.breakdown}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Vacuum Pipe Cleaner"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.vacuumPipeCleaner}
                                                    name="vacuumPipeCleaner"
                                                    error={!!touched.vacuumPipeCleaner && !!errors.vacuumPipeCleaner}
                                                    helperText={touched.vacuumPipeCleaner && errors.vacuumPipeCleaner}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Oil Leak"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.oilLeak}
                                                    name="oilLeak"
                                                    error={!!touched.oilLeak && !!errors.oilLeak}
                                                    helperText={touched.oilLeak && errors.oilLeak}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Pump Chamber"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.pumpChamber}
                                                    name="pumpChamber"
                                                    error={!!touched.pumpChamber && !!errors.pumpChamber}
                                                    helperText={touched.pumpChamber && errors.pumpChamber}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Mould Change"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.mouldChange}
                                                    name="mouldChange"
                                                    error={!!touched.mouldChange && !!errors.mouldChange}
                                                    helperText={touched.mouldChange && errors.mouldChange}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Load Shedding"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.loadShedding}
                                                    name="loadShedding"
                                                    error={!!touched.loadShedding && !!errors.loadShedding}
                                                    helperText={touched.loadShedding && errors.loadShedding}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Load Curtailment"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.loadCurtailment}
                                                    name="loadCurtailment"
                                                    error={!!touched.loadCurtailment && !!errors.loadCurtailment}
                                                    helperText={touched.loadCurtailment && errors.loadCurtailment}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Greasing Mould"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.greasingMould}
                                                    name="greasingMould"
                                                    error={!!touched.greasingMould && !!errors.greasingMould}
                                                    helperText={touched.greasingMould && errors.greasingMould}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Material Change"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.materialChange}
                                                    name="materialChange"
                                                    error={!!touched.materialChange && !!errors.materialChange}
                                                    helperText={touched.materialChange && errors.materialChange}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Toolroom Fitting"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.toolroomFitting}
                                                    name="toolroomFitting"
                                                    error={!!touched.toolroomFitting && !!errors.toolroomFitting}
                                                    helperText={touched.toolroomFitting && errors.toolroomFitting}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Water Pipe Burst"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.waterPipeBurst}
                                                    name="waterPipeBurst"
                                                    error={!!touched.waterPipeBurst && !!errors.waterPipeBurst}
                                                    helperText={touched.waterPipeBurst && errors.waterPipeBurst}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    fullWidth
                                                    variant="filled"
                                                    type="text"
                                                    label="Water Channel Blocked"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.waterChannelBlocked}
                                                    name="waterChannelBlocked"
                                                    error={!!touched.waterChannelBlocked && !!errors.waterChannelBlocked}
                                                    helperText={touched.waterChannelBlocked && errors.waterChannelBlocked}
                                                    sx={{gridColumn: "span 1"}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid xs={6}></Grid>
                                        <Grid xs={2}></Grid>

                                    </Grid>
                                    <Box display="flex" justifyContent="end" mt="20px">
                                        <Button type="submit" color="secondary" variant="contained">
                                            Generate Daily Budget
                                        </Button>
                                    </Box>
                                </form>
                            )}

                        </Formik>
                    </Box>
                </Modal>
                <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: colours.palette.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colours.palette.blueAccent[700],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colours.palette.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colours.palette.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colours.palette.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colours.palette.grey[100]} !important`,
                        },
                    }}
                >
                    {machinePerformance &&
                        <DataGrid
                            getRowId={getRowId}
                            rows={machinePerformance}
                            columns={columns}
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            slots={{
                                toolbar: EditToolbar,
                            }}
                            slotProps={{
                                toolbar: {machinePerformance, setRowModesModel},
                            }}
                        />}
                </Box>
            </Box>
        </LocalizationProvider>
    );
};


export default ShiftData;